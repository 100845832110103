body {
  font-family: 'Montserrat', sans-serif;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.diagonal-split-background {
  background-color: #ffffff;
  background-image: -webkit-linear-gradient(45deg, #d2d3d3 90%, #034a8d 50%);
}

.loginPage {
  background-color: #e5e5f7;
  color: #22335423;
  /* opacity: 0.8; */
  background-image: radial-gradient(#444cf7 0.9500000000000001px, #e5e5f7 0.9500000000000001px);
  background-size: 19px 19px;
}

.ProseMirror-focused {
  outline: none;
}